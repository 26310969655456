<template>
    <div class="map" id="map">
      <span style="">{{ sync }}</span>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
  props: ['waypoints', 'weight', 'sync'],
  components: { L },
  data() {
    return {
      map: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 7,
      geoserverUrl: "https://www.thepavementislava.com/geoserver",
      // geoserverUrl: "http://76.131.216.213:7080/geoserver",
      icon: null,
      loadingIcon: null,
      nextWaypointId: 0,
      loadingFromUrl: true
    }
  },
  updated() {
    // console.log('updated')
    this.clearMapLayers()
    this.applyMask()
    
    if (this.loadingFromUrl) {
        // console.log('Loading from URL')
        this.loadingFromUrl = false
        // var urlParams = new URLSearchParams(window.location.search)
        // var r = urlParams.get('r')
        // if (r != null && r.length > 0) {
        //   var wps = urlParams.get('r').split(':')
        //   wps.forEach((wp) => {
        //     var vw = wp.split(',')
        //     var coords = this.getPointForVertex(vw[0])
            
        //     this.addWaypoint(coords, vw[0], vw[1])
        //   })  
        // }
        // console.log('Done. Loading')
        // console.log('emitting from updated (loadingFromUrl)')
        // console.log(this.waypoints)
        // this.$emit('refreshed')
        // if (this.waypoints.length % 2 != 0) {
        //   // call emit again, to make sure the valye of sync changes, to force a refresh
        //   this.$emit('refreshed')
        // }

    } else {
      if (this.waypoints.length > 0 ){
        this.waypoints.forEach((waypoint, index) => {
          if(waypoint.marker != null && !(this.map.hasLayer(waypoint.marker))) {
            this.map.addLayer(waypoint.marker)
          }
          if(waypoint.layer != null && !(this.map.hasLayer(waypoint.layer))) {
            this.map.addLayer(waypoint.layer)
            // previous waypoint marker isn't loading and can be draggle
            this.waypoints[index-1].marker.setIcon(this.icon)
            this.waypoints[index-1].marker.dragging.enable()
            // set this waypoint marker to not loading and draggable if it is the last marker, or if the next waypoint has a layer
            if (index == this.waypoints.length -1 || this.waypoints[index+1].layer != null) {
              waypoint.marker.setIcon(this.icon)
              waypoint.marker.dragging.enable()
            }
          } else if (waypoint.layer == null && index > 0) {
            this.addRouteToMap(this.waypoints[index-1], waypoint)
          }
        })
      }
    }
    // this.updateUrl()
    // console.log('exiting updated')
  },
  mounted() {
    var urlParams = new URLSearchParams(window.location.search)
    var z = urlParams.get('z')
    if (z === null) {
      z = this.zoom
    } 
    var lat = urlParams.get('lat')
    if (lat === null) {
        lat = 39.037844
    }
    var lon = urlParams.get('lon')
    if (lon === null) {
        lon = -105.11350
    }

    this.map = L.map("map").setView([lat, lon], z)
    L.tileLayer(this.url).addTo(this.map)
    this.map.on("click", (e) => {
      this.addWaypointToMap(e)
    })
    this.map.on("zoomend", () => {
      // this.updateUrl()
    })
    this.map.on("moveend", () => {
      // this.updateUrl()
    })

    this.icon = L.icon({
      iconUrl: require('@/assets/marker-icon.png'),
      iconAnchor: [12,42]
    })

    this.loadingIcon = L.icon({
      iconUrl: require('@/assets/marker-icon.png'),
      iconAnchor: [12,42],
      className: 'loading'
    })

    this.applyMask()
    
  },
  methods: {
    nearestVertexUrl(lat, lon) {
      return `${this.geoserverUrl}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=tpil:nearest_vertex&outputformat=application/json&viewparams=x:${lon};y:${lat};`
    },
    dirtyPathUrl(source, target, inWeight) {
      return `${this.geoserverUrl}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=tpil:dirty_path&outputformat=application/json&viewparams=weight:${inWeight};source:${source};target:${target};`
    },
    vertexPointUrl(vertex) {
      return `${this.geoserverUrl}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=tpil:vertex_point&viewparams=vertex:${vertex};&outputformat=application/json` 
    },
    
    addWaypointToMap(e) {
      $.ajax({
        url: this.nearestVertexUrl(e.latlng.lat, e.latlng.lng),
        async: true,
        success: function(data) {
          var coordinates = data.features[0].geometry.coordinates;
          var vertex = data.features[0].properties.id;
          var marker = L.marker([coordinates[1], coordinates[0]], { 
            icon: this.icon, 
            draggable: true
          })
          marker.on('dragend', (e) => { 
            this.waypointMoved(e) 
          })
          var waypoint = { id: this.nextWaypointId++, vertex: vertex, marker: marker, weight: this.weight, layer: null }
          // console.log('emitting from addWaypointToMap')
          this.$emit('newwaypoint', waypoint)
        }.bind(this)
      })
    },

    addWaypoint(coords, vertex, weight) {
        // this one is using when loading a route from the url
        var marker = L.marker([coords.lat, coords.lon], { 
            icon: this.icon, 
            draggable: true
          })
        marker.on('dragend', (e) => { 
          this.waypointMoved(e) 
        })

        // console.log('emitting from addWaypoint')
        this.$emit('newwaypoint', { id: this.nextWaypointId++, vertex: vertex, marker: marker, weight: weight, layer: null })
    },

    waypointMoved(e) {
      var index = this.findWaypointIndex(e.target)
      if (index == null) {
        // console.log('Error finding waypoint')
        return
      }

      var waypoint = this.waypoints[index]

      // Remove this waypoint's layer (preceding)
      if (waypoint.layer != null) {
        if (this.map.hasLayer(waypoint.layer)) {
          this.map.removeLayer(waypoint.layer)
          waypoint.layer = null
        }
      }
      // If this isn't the last waypoint, we also need to remove the next waypoint's layer (proceding)
      if (index < this.waypoints.length - 1) {
        if (this.map.hasLayer(this.waypoints[index+1].layer)) {
          this.map.removeLayer(this.waypoints[index+1].layer)
          this.waypoints[index+1].layer = null
        }
      }

      $.ajax({
        url: this.nearestVertexUrl(e.target._latlng.lat, e.target._latlng.lng),
        async: true,
        success: function(data) {
          var vertex = data.features[0].properties.id

          // console.log('emitting from waypointMoved')
          this.$emit('waypointmoved', { id: waypoint.id, vertex: vertex })

        }.bind(this)
      })

    },
    
    addRouteToMap(sourceWaypoint, targetWaypoint) {
      var routeLayer = null
 
      sourceWaypoint.marker.setIcon(this.loadingIcon)
      sourceWaypoint.marker.dragging.disable()

      targetWaypoint.marker.setIcon(this.loadingIcon)
      targetWaypoint.marker.dragging.disable()

      $.getJSON({
        url: this.dirtyPathUrl(sourceWaypoint.vertex, targetWaypoint.vertex, (targetWaypoint.weight / 2)),
        async: true,
        success: function(data) {
          routeLayer = L.geoJSON(data, {
            style: function(feature) {
              switch (feature.properties.paved) {
                case 't': return { color: "#000000" };
                case 'f': return { color: "#B47F29" };
              }
            }
          });
          
          // console.log('emitting from routeAdded')
          this.$emit('routeadded', { id: targetWaypoint.id, layer: routeLayer })

        }.bind(this)
      })
    },

    getPointForVertex(vertex) {
      var coords = {}
      $.ajax({
        url: this.vertexPointUrl(vertex),
        async: false,
        success: function(data) {
          var c = data.features[0].geometry.coordinates
          coords = { lat: c[1], lon: c[0] }
        }
      })
      return coords
    },

    findWaypointIndex(marker) {
      for (var i = 0; i < this.waypoints.length; i++) {
        var waypoint = this.waypoints[i]
        if (waypoint.marker._latlng.lat == marker._latlng.lat &&
            waypoint.marker._latlng.lon == marker._latlng.lon) {
          return i
        }
      }
      // console.log('did not find waypoint')
      return null
    },
    
    findWaypointById(id) {
        for (var i = 0; i < this.waypoints.length; i++) {
        var waypoint = this.waypoints[i]
        if (waypoint.id == id) {
          return waypoint
        }
      }
    },

    clearMapLayers() {
      this.map.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer)) {
          this.map.removeLayer(layer)
        }
      })
    },

    // updateUrl() {
    //     var params=`?z=${this.map.getZoom()}`

    //     var latlng = this.map.getCenter()
    //     params += `&lat=${latlng.lat}&lon=${latlng.lng}`


    //     params +='&r='
    //     this.waypoints.forEach((waypoint, index) => {
    //         params += `${waypoint.vertex},${waypoint.weight}`
    //         if ((index+1) < this.waypoints.length ) {
    //             params += ':'
    //         }
    //     })
    //     window.history.replaceState(null, null, params)
    // },

    applyMask() {
      // mask map areas that have no data
      L.Mask = L.Polygon.extend({
        options: {
          stroke: false,
		      color: '#333',
		      fillOpacity: 0.5,
		      clickable: true,
		      outerBounds: new L.LatLngBounds([-90, -360], [90, 360])
        },
        initialize: function (latLngs, options) {
          
          var outerBoundsLatLngs = [
            this.options.outerBounds.getSouthWest(),
            this.options.outerBounds.getNorthWest(),
            this.options.outerBounds.getNorthEast(),
            this.options.outerBounds.getSouthEast()
          ];
          L.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);	
        }
      })

      var latLngs = []
      // colorado boundaries.. close enough
      latLngs.push(new L.LatLng(41.03, -109.06))
      latLngs.push(new L.LatLng(41.03, -102.00))
      latLngs.push(new L.LatLng(36.95, -102.00))
      latLngs.push(new L.LatLng(36.95, -109.10))

      var mask = new L.Mask(latLngs)
      mask.addTo(this.map)
    }
  }
}
</script>

<style>
.map {
  flex: 1;
  cursor: pointer;
}

@keyframes fade { 
  from { opacity: 0.25; } 
}

.loading {
  animation: fade .4s infinite alternate;
}

</style>
<template>
  <div class="container">
    <div class="top">
      <Details :waypoints="waypoints" :sync="sync" />
      <Actions :sync="sync" 
        :waypoints="waypoints" 
        :weight="weight" 
        @clear="clearRoute"
        @weightchanged="weightChanged" />
    </div>
    <Map :sync="sync" 
      :waypoints="waypoints" 
      :weight="weight"
       @newwaypoint="addWaypoint"
       @waypointmoved="moveWaypoint" 
       @routeadded="routeAdded"
       @refreshed="refresh"/>
  </div>
</template>

<script>
import Map from './components/Map.vue'
import Details from './components/Details.vue'
import Actions from './components/Actions.vue'

export default {
  name: 'The Pavement is Lava',
  components: {
    Map,
    Details,
    Actions
  },
  data() {
    return {
      waypoints: [],
      weight: 6,
      sync: 0
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      // console.log('refresh called')
      // console.log(this.sync)
      this.sync++
    },
    clearRoute() {
      this.waypoints = []
      this.refresh()
    },
    addWaypoint(waypoint) {
      this.waypoints.push(waypoint)
      this.refresh()
    }, 
    routeAdded(route) {
      this.findWaypointById(route.id).layer = route.layer
      this.refresh()
    },
    moveWaypoint(waypoint) {
      this.findWaypointById(waypoint.id).vertex = waypoint.vertex
      this.refresh()
    },
    weightChanged(newWeight) {
      this.weight = newWeight
      this.refresh()
    },
    findWaypointById(id) {
      for (var i = 0; i < this.waypoints.length; i++) {
        if (this.waypoints[i].id == id) {
          return this.waypoints[i]
        }
      }
    }
  }
}
</script>

<style>

.container {
  display: flex;
  flex-direction: column;
  height: 97vh;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

</style>

<template>
  <div class="details">
    <span style="display: none;">{{ sync }}</span>
    <span v-if="this.waypoints.length == 0">Click on the map to add a waypoint</span>
    <span v-if="this.waypoints.length == 1">Click on the map again to create a route</span>
    <span v-if="this.waypoints.length > 1"> Miles: {{ totalMiles }} Unpaved: {{totalUnpaved}} ({{ unpavedPercent }}%)</span>
  </div>

</template>

<script>
export default {
  props: ['waypoints', 'sync'],
  data() {
    return {
      totalMiles: 0,
      totalPaved: 0,
      totalUnpaved: 0,
      unpavedPercent: 0,
      numRoutes: 0
    }
  },
  updated() {
    // only recalculate the mileage if the number of layers has changed
    if (this.routesInWaypoints() != this.numRoutes) {
      this.calculateMileage()
    }
  },
  methods: {
    calculateMileage() {
      var kilosToMiles = .621371
      var total = 0
      var paved = 0
      var unpaved = 0

      // reset numRoutes
      this.numRoutes = 0

      for (var i = 0; i < this.waypoints.length; i++) {
        if (this.waypoints[i].layer == null) {
          continue
        }

        this.numRoutes++

        var geoJson = this.waypoints[i].layer.toGeoJSON()

        for (var j = 0; j < geoJson.features.length; j++) {
            var f = geoJson.features[j]
            total += f.properties.distance
            if (f.properties.paved == 't') {
              paved += f.properties.distance
            } else {
              unpaved += f.properties.distance
            }
        }
      }
      this.totalMiles = (kilosToMiles * total).toFixed(2)
      this.totalPaved = (kilosToMiles * paved).toFixed(2)
      this.totalUnpaved = (kilosToMiles * unpaved).toFixed(2)
      this.unpavedPercent = this.totalMiles == 0 ? 0 : ((this.totalUnpaved / this.totalMiles) * 100).toFixed(2)
    },
    routesInWaypoints() {
      // returns the number of non-null routes in the waypoints array
      var count = 0
      for (var i = 0; i < this.waypoints.length; i++) {
        if (this.waypoints[i].layer == null) {
          continue
        }
        count++
      }
      return count
    }
  }
  
}
</script>

<style scoped>
.details {
  flex: 1;
  width: 50%;
  padding: 13px;
  font-size: 18px;
  font-weight: 750;
  color: #FF5252;
  background-color: #F8F8F8;
  line-height: 1.5;
  font-family: Arial, Verdana, Roboto, "Open Sans", Georgia, sans-serif;
}
</style>
<template>
  <div class="actions">
    <span style="display: none;">{{ sync }}</span>
    <span class="pal-action"><input type="range" min="1" max="30" value="6" id="slider" ref="slider" :title="palDescription" v-on:change="weightChanged"/></span>
    <span class="action" v-on:click="clearRoute">Clear Route</span>
    <span class="action" v-on:click="downloadGPX">Download GPX</span>
  </div>
</template>

<script>
var builder = require('xmlbuilder');

export default {
  props: ['sync', 'waypoints', 'weight'],
  data() {
    return {
      palDescription: ''
    }
  },
  mounted() {
    this.weightChanged()
  },
  methods: {
    weightChanged() {
      var newWeight = slider.value
      this.palDescription = `${newWeight} - `
      if (newWeight == 1) {
        this.palDescription += 'Just slab it!'
      } else if (newWeight <= 3) {
        this.palDescription += "I like dirt, but I'm in a hurry!"
      } else if (newWeight <= 8) {
        this.palDescription += 'A good compromise'
      } else if (newWeight <= 16) {
        this.palDescription += 'Avoid pavement if at all possible!'
      } else {
        this.palDescription += 'The pavement is LAVA!'
      }


      slider.style.setProperty('--slider-value', slider.value);
      this.$emit('weightchanged', slider.value)
    },
    clearRoute() {
      this.$emit('clear')
    },
    downloadGPX() {
      var gpx = this.createGPX()
      var filename = `tpil-${new Date().getTime()}.gpx`
      var element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(gpx));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element); 
    },
    createGPX() {
      var gpx = { 
        gpx: {
          '@xmlns': 'http://www.topografix.com/GPX/1/1',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          '@xsi:schemaLocation': 'http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd',
          '@version': '1.1',
          '@creator': 'The Pavement is Lava!',
          wpt: [],
          trk: [],  
        }
      }
      
      this.waypoints.forEach((waypoint, index) => {
        if (waypoint.layer == null) {
            // null layer, skipping
        } else {
          var trk = {  
            name: "Dirtiest Path " + new Date().getTime() + " Segment " + index,
            trkseg: [ { trkpt: [] } ]
          }

          var geojson = waypoint.layer.toGeoJSON()
          var features = geojson.features 

          features.forEach((feature) => {
            switch(feature.geometry.type) {
              case "LineString":
              case "MultiLineString":
                var coords = feature.geometry.coordinates;
                if (feature.geometry.type == "LineString") {
                  coords = [coords];
                }
                coords.forEach((coordinates) => {
                  coordinates.forEach((c) => {
                    // don't add point if it's the same as the last point we added
                    var points = trk.trkseg[0].trkpt
                    if (points.length > 0 &&
                        points[points.length - 1]['@lat'] ==  c[1] &&
                        points[points.length - 1]['@lon'] ==  c[0]) {
                      // no-op 
                    } else {
                      trk.trkseg[0].trkpt.push({
                        '@lat': c[1],
                        '@lon': c[0]
                      })
                    }
                  })
                })

                break;
              default:
                console.log("Unsupported GeoJSON feature")
            } // switch
          })
        }
        // push trk onto gpx
        gpx.gpx.trk.push(trk)
      })

      return builder.create(gpx).end({ pretty: true })
    }
  }
}
</script>

<style scoped>
.actions {
  flex: 1;
  width: 50%;
  text-align: right;
  padding: 10px;
  background-color: #F8F8F8;
}

.action {
  display: inline-block;
  padding: 5px 10px;
  background-color: #FF4136;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 2px;
}

.pal-action {
  display: inline-block;
  padding: 0px 10px;
  border: none;
  vertical-align: -8px;
}

.action:hover {
  background-color: #E71D36;
}

#slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background-color: transparent;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

#slider::-webkit-slider-runnable-track {
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  z-index: -1;
}

#slider::-moz-range-track {
  height: 10px;
  border-radius: 5px;
  background-color: #ddd;
  z-index: -1;
}

#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  background: url('../assets/bikethumb.svg');
  /* background-color: blue; */
  border-radius: 50%;
  position: relative;
  top: -25px;
  z-index: 2;
  cursor: pointer;
}

#slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% * (var(--slider-value) / 30));
  height: 10px;
  background-color: #B47F29;
  border-radius: 5px;
  z-index: 0;
}

</style>